import { useMemo } from 'react';
import { useNftAvatarMetadata } from 'cb-wallet-data/stores/Collection/hooks/useNftAvatarMetadata';
import { PublicProfile } from 'cb-wallet-data/stores/DecentralizedID/types';

export function useDynamicPfpData(
  userAddress?: string,
  userProfile?: PublicProfile,
  avatar?: string,
) {
  const defaultUserProfile = useMemo((): PublicProfile => {
    return {
      primaryDomainType: 'cbid',
      address: userAddress || '',
    };
  }, [userAddress]);

  const publicProfile = useMemo(() => {
    return userProfile ?? defaultUserProfile;
  }, [userProfile, defaultUserProfile]);

  const avatarImageURL = useMemo(() => {
    if (avatar) {
      return avatar;
    }
    return publicProfile?.primaryDomainType === 'eth'
      ? publicProfile?.ensDomainProfile?.profileTextRecords.avatar
      : publicProfile?.subdomainProfile?.profileTextRecords.avatar;
  }, [avatar, publicProfile]);

  const nftAvatarMetadata = useNftAvatarMetadata(avatarImageURL);

  return useMemo(() => {
    return { publicProfile, avatarImageURL, nftAvatarMetadata };
  }, [publicProfile, avatarImageURL, nftAvatarMetadata]);
}
